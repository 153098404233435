/* Ionic Variables and Theming. */
/* This is just a placeholder file For more info, please see: */
/* https://ionicframework.com/docs/theming/basics */

/* To quickly generate your own theme, check out the color generator */
/* https://ionicframework.com/docs/theming/color-generator */

/** Ionic CSS Variables **/
:root {
  --primary: #ffffff;
	--secondary: #ffffff;
	--tertiary: #ffffff;
	--quaternary: #FFB700;

	--ion-color-primary: #0000D2;
	--ion-color-secondary: #00D1CB;
	--ion-color-tertiary: #00ADF8;
	--ion-color-light: #5457E7;

	--ion-color-tertiary-rgb: 245, 245, 245;
	--ion-color-tertiary-contrast: #000000;
	--ion-color-tertiary-contrast-rgb: 0, 0, 0;
	--ion-color-tertiary-shade: #d8d8d8;
	--ion-color-tertiary-tint: #f6f6f6;
	--text-color: #232C34;

	// --modulo-desempeno-primary: #5457E7;
	// --modulo-desempeno-secondary: #2E36CE;
	// --modulo-desempeno-disable: #9395EE;
	// --modulo-desempeno-tertiary: #57BFE3;

	--modulo-desempeno-page-background: #F7F8F8;
	--gray-secondary-color: #A9B2B9;


  --ion-color-success: var(--ion-color-primary);
  --ion-color-shade: var(--primary-focused) !important;
  --ion-color-tint: var(--primary-focused) !important;
  --ion-color-primary-tint: var(--primary-focused) !important;
  --ion-color-primary-shade: var(--primary-focused) !important;

  --warning-status: #ffb700;
  --confirm-status: #00e396;
  --error-status: #ff4560;
  --informative-status: #2E36CE;
}
